import React from "react";

export const iconPaths = {
    check: {
        path: (
            <g fill="currentColor">
                <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039" />
            </g>
        ),
        viewBox: "0 0 14 14"
    },
    "question-outline": {
        path: (
            <g stroke="currentColor" strokeWidth="1.5">
                <path strokeLinecap="butt" fill="none" d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25" />
                <path
                    fill="none"
                    strokeLinecap="butt"
                    d="M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
                />
                <circle fill="none" strokeMiterlimit="10" cx="12" cy="12" r="11.25" />
            </g>
        )
    }
};
