import { envConstants, frontyEnv } from "Constants";
import { GetState, SetState, State, StateCreator, StoreApi } from "zustand";
import { devtools, NamedSet, StoreApiWithDevtools } from "zustand/middleware";

type CreateStateReturnTypeWithoutDevtools<TState extends State> =
    | StateCreator<TState, SetState<TState>, GetState<TState>, any>
    | StoreApi<TState>;

type CreateStateReturnTypeWithDevtools<TState extends State> =
    | CreateStateReturnTypeWithoutDevtools<TState>
    | StoreApiWithDevtools<TState>
    | ((set: SetState<TState>, get: GetState<TState>, api: StoreApi<TState> & StoreApiWithDevtools<TState>) => TState);

type DevToolsInputType<TState extends State> = (
    set: NamedSet<TState>,
    get: GetState<TState>,
    api: StoreApi<TState>
) => TState;

export const wrapZustandStoreWithDevToolsIfNotProd = <TState extends State>(
    createState: StateCreator<TState, SetState<TState>, GetState<TState>, any> | StoreApi<TState>
): CreateStateReturnTypeWithDevtools<TState> | CreateStateReturnTypeWithoutDevtools<TState> => {
    // Just return the store as is
    if (envConstants.PROFILE == frontyEnv.PROD) {
        return createState;
    }

    // If the redux devtools extension is not installed, just return the store as is
    // This eliminates the warnings in the console when the extension is not installed (i.e. tests)
    if (!window.__REDUX_DEVTOOLS_EXTENSION__) {
        return createState;
    }

    // NOTE: Not sure if we want this enabled on the dev server.  It's exposed to the public, but we often try to reproduce errors there.
    // Thoughts?
    // This allows you to see the store via the redux devtool plugin in the browser
    return devtools(createState as DevToolsInputType<TState>);
};
