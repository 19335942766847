import { paymentTabStatusTypes } from "Constants";
import {
    CREATE_POS_POSTPONED_PAYMENT_ORDER,
    EXTEND_POSTPONED_PAYMENT_ORDER,
    UPSERT_ORDER_WINDOW,
    UPSERT_ORDER_WINDOW_AND_ORDERS
} from "GraphQLMutations";
import { getExtendedOrderProducts, getOrderWindowsForTable, getVisibleOrderWindows } from "Providers";
import { createTrackedSelector } from "react-tracked";
import {
    buildOrderInput,
    getPostponedInfo,
    handleFailedOrder,
    makePostPonedOrder,
    makePostponeExtendOrder,
    PostponedInfo
} from "../admin/components/poses/pos/utils";
import { newMothershipApolloClient } from "../graphql/clients";
import { posStore, qoplaStore, orderWindowsStore } from "Stores";
import { CartProduct, OrderWindow, Table } from "Types";
import create from "zustand";
import { isEqual } from "lodash";
import { confirmOrCancelNotification } from "TempUtils";
import { wrapZustandStoreWithDevToolsIfNotProd } from "./storeUtils";

type OrderWindowsToMove = { ordersToMove: OrderWindow[]; oldTableName: string };

export type TableStore = {
    // Table service state
    // This stores whether the shop has tables associated with it or not
    hasShopTables: boolean;
    setHasShopTables: (hasShopTables: boolean) => void;

    // This is true when a table has been opened.  False after the table is closed
    isTableOpen: boolean;
    setIsTableOpen: (isTableOpen: boolean) => void;

    // This holds the table which is selected in the table selection UI.  Also points to the open table, if there is one
    selectedTable: Table | null;
    setSelectedTable: (table: Table | null) => void;

    // This is true when a table has been opened.  False after the table is closed
    // This holds the table which is selected in the table selection UI.  Also points to the open table, if there is one
    isInTableSelectionMode: boolean;
    setIsInTableSelectionMode: (isInTableSelectionMode: boolean) => void;

    orderWindowsToMove: OrderWindowsToMove;
    setOrderWindowsToMove: (orderWindowsToMove: OrderWindowsToMove) => void;

    openTable: () => void;
    closeTable: (table: Table | undefined | null) => void;
    moveTableOrder: (tableName: string, tableId: string) => void;
    cancelMoveTable: () => void;
    moveOrderToNewTable: (table: Table) => void;
    onSetSelectedTable: (table: Table) => void;
    onExitTableMode: () => void;
    enterTableSelectionMode: () => void;
    exitTableSelectionMode: () => void;
    checkResetCloseTable: () => void;
};

export const tableStore = create<TableStore>(
    wrapZustandStoreWithDevToolsIfNotProd((set, get) => ({
        hasShopTables: false,
        setHasShopTables: (hasShopTables: boolean) => set(() => ({ hasShopTables })),
        isTableOpen: false,
        setIsTableOpen: (isTableOpen: boolean) => set(() => ({ isTableOpen })),
        selectedTable: null,
        setSelectedTable: (selectedTable: Table | null) =>
            set(() => {
                const { orderWindows, setActiveOrderWindowId } = orderWindowsStore.getState();

                const visibleOrderWindows = getVisibleOrderWindows(orderWindows, selectedTable?.id, []);
                if (!!visibleOrderWindows?.length) {
                    setActiveOrderWindowId(visibleOrderWindows[0]?.id);
                }
                return { selectedTable };
            }),
        isInTableSelectionMode: false,
        setIsInTableSelectionMode: (isInTableSelectionMode: boolean) => set(() => ({ isInTableSelectionMode })),
        orderWindowsToMove: { ordersToMove: [], oldTableName: "" },
        setOrderWindowsToMove: (orderWindowsToMove: OrderWindowsToMove) =>
            set(() => ({
                orderWindowsToMove
            })),

        openTable: () => {
            const { orderWindows, setActiveOrderWindowId } = orderWindowsStore.getState();
            get().setIsTableOpen(true);
            get().exitTableSelectionMode();

            const visibleTableOrderWindows = getVisibleOrderWindows(orderWindows, get().selectedTable?.id);
            const newActiveOrderWindowId = !!visibleTableOrderWindows.length ? visibleTableOrderWindows[0].id : null;

            setActiveOrderWindowId(newActiveOrderWindowId);
        },

        onExitTableMode: () => {
            const { orderWindows, setActiveOrderWindowId } = orderWindowsStore.getState();
            get().setSelectedTable(null);
            get().setIsTableOpen(false);
            get().exitTableSelectionMode();

            // Update activeOrderWindowId due to new visibleOrderWindows
            const visibleOrderWindows = getVisibleOrderWindows(orderWindows, null);
            const newActiveOrderWindowId = !!visibleOrderWindows.length ? visibleOrderWindows[0].id : null;

            setActiveOrderWindowId(newActiveOrderWindowId);
        },
        checkResetCloseTable: () => {
            const { orderWindows } = orderWindowsStore.getState();

            // Get all orderwindows with same table id
            const orderWindowsInSameTable = orderWindows.filter(o => {
                const tableId = o.tableId || "";
                return tableId === get().selectedTable?.id;
            });

            // If a selected table and no more than 1 orderwindow, then reset states
            if (get().selectedTable && orderWindowsInSameTable.length <= 1) {
                get().setSelectedTable(null);
                get().setIsTableOpen(false);
            }
        },
        closeTable: async (table: Table | undefined | null) => {
            const {
                orderWindows,
                getActiveOrderWindow,
                addCartToDTPreviousCart,
                attachContactInformationToPostponedOrderWindow
            } = orderWindowsStore.getState();
            const { selectedPos, updateActivePaymentTabStatus, getActivePaymentTab, selectedTerminal } =
                posStore.getState();
            const { selectedShop } = qoplaStore.getState();

            const activeOrderWindow = getActiveOrderWindow();
            const tableOrderWindows = getOrderWindowsForTable(orderWindows, activeOrderWindow?.tableId || null);

            const isActiveOrderWindowForThisTable = tableOrderWindows.some(
                tableOrderWindow => tableOrderWindow.id == activeOrderWindow?.id
            );

            if (!isActiveOrderWindowForThisTable || !table) {
                // Just go back to the POS UI
                get().onExitTableMode();
                return;
            }

            /**
             * At this point, we know that the active window is for this table specifically
             *  and we can safely close it
             */

            // Even if POS is in DT mode, it's not a DT order if we have a selectedTable
            const isDriveThroughOrder = (selectedPos?.postponePaymentEnabled && !get().selectedTable) || false;

            let isTableModified = false;

            tableOrderWindows.forEach(async tableOrderWindow => {
                const isNewTableOrderWindow =
                    !tableOrderWindow.previousCartProducts.length && !!tableOrderWindow?.cartProducts?.length;
                const orderProductsToExtend = getExtendedOrderProducts(tableOrderWindow);
                const hasOrderProductsToExtend = orderProductsToExtend?.length > 0;
                const shouldExtendOrder = tableOrderWindow.postponeOrderId && hasOrderProductsToExtend;

                if (isNewTableOrderWindow && !!activeOrderWindow) {
                    isTableModified = true;
                    const orderProducts = tableOrderWindow?.cartProducts?.map(
                        (cartProduct: CartProduct) => cartProduct.orderProduct
                    );
                    const shouldPostponePayment = true;
                    const orderInput = buildOrderInput(
                        isDriveThroughOrder,
                        activeOrderWindow,
                        selectedShop,
                        selectedPos?.id,
                        orderProducts,
                        shouldPostponePayment,
                        tableOrderWindow.puckNo,
                        0, // TODO splitOrderNo,
                        false, // TODO splitOrderNo,
                        table
                    );

                    const createPostponedPaymentOrder = (variableObject: any) =>
                        newMothershipApolloClient.mutate({
                            mutation: CREATE_POS_POSTPONED_PAYMENT_ORDER,
                            variables: variableObject.variables
                        });
                    const order = await makePostPonedOrder(orderInput, createPostponedPaymentOrder, tableOrderWindow);

                    if (order?.failedReason) {
                        handleFailedOrder(tableOrderWindow.id, order, updateActivePaymentTabStatus);
                    } else {
                        addCartToDTPreviousCart(tableOrderWindow.id);
                        updateActivePaymentTabStatus(
                            tableOrderWindow.id,
                            true,
                            "Sparat",
                            `Bord: ${table.name} har sparats`,
                            paymentTabStatusTypes.POSTPONED_CREATED
                        );

                        // Can't rely on synchronous setState to have the most updated orderWindow
                        // Has to return the updatedOrderWindow from function so we always have
                        // postponeOrderId
                        const updatedOrderWindow = attachContactInformationToPostponedOrderWindow(
                            tableOrderWindow.id,
                            order!.id,
                            null
                        );
                        newMothershipApolloClient.mutate({
                            mutation: UPSERT_ORDER_WINDOW,
                            variables: { orderWindow: updatedOrderWindow }
                        });
                    }
                } else if (shouldExtendOrder && !!activeOrderWindow) {
                    isTableModified = true;
                    const orderInput = buildOrderInput(
                        isDriveThroughOrder,
                        activeOrderWindow,
                        selectedShop,
                        selectedPos?.id,
                        orderProductsToExtend,
                        true,
                        tableOrderWindow.puckNo,
                        0, // TODO splitOrderNo,
                        false, // TODO splitOrderNo,
                        table
                    );

                    const activePaymentTab = getActivePaymentTab(tableOrderWindow.id);
                    const postponeInfo = getPostponedInfo(tableOrderWindow, activePaymentTab) as PostponedInfo;

                    const extendPostponedPaymentOrder = (variableObject: any) =>
                        newMothershipApolloClient.mutate({
                            mutation: EXTEND_POSTPONED_PAYMENT_ORDER,
                            variables: variableObject.variables
                        });
                    const order = await makePostponeExtendOrder(
                        orderInput,
                        postponeInfo,
                        selectedTerminal?.terminalId,
                        extendPostponedPaymentOrder
                    );

                    if (order?.failedReason) {
                        handleFailedOrder(activeOrderWindow.id, order, updateActivePaymentTabStatus);
                        return;
                    }

                    updateActivePaymentTabStatus(
                        activeOrderWindow.id,
                        true,
                        "Utökat",
                        `Utökat för Table: ${table?.name}`,
                        paymentTabStatusTypes.POSTPONED_EXTENDED
                    );
                    addCartToDTPreviousCart(activeOrderWindow?.id);

                    // So we can rely on the orderWindow.previousCartProducts when calculating extending the order
                    newMothershipApolloClient.mutate({
                        mutation: UPSERT_ORDER_WINDOW,
                        variables: {
                            orderWindow: { ...tableOrderWindow, previousCartProducts: tableOrderWindow?.cartProducts }
                        }
                    });
                } else {
                    const hasUpdatedOrderProductPrice = !isEqual(
                        getExtendedOrderProducts(tableOrderWindow, true),
                        getExtendedOrderProducts(tableOrderWindow, false)
                    );
                    const hasRemovedCartProducts =
                        tableOrderWindow.cartProducts.length < tableOrderWindow.previousCartProducts.length;
                    const isOrderWindowModified = hasUpdatedOrderProductPrice || hasRemovedCartProducts;

                    if (isOrderWindowModified) {
                        isTableModified = true;
                        updateActivePaymentTabStatus(
                            tableOrderWindow.id,
                            true,
                            "Sparat",
                            `Bord: ${table.name} har sparats`,
                            paymentTabStatusTypes.POSTPONED_CREATED
                        );

                        newMothershipApolloClient.mutate({
                            mutation: UPSERT_ORDER_WINDOW,
                            variables: {
                                orderWindow: {
                                    ...tableOrderWindow,
                                    previousCartProducts: tableOrderWindow?.cartProducts
                                }
                            }
                        });
                    }
                }
            });

            if (!isTableModified) {
                get().onExitTableMode();
            }
        },
        moveTableOrder: (tableName: string, tableId: string) => {
            const { orderWindows } = orderWindowsStore.getState();
            const { orderWindowsToMove, setOrderWindowsToMove, setSelectedTable } = get();
            if (!orderWindowsToMove.ordersToMove.length) {
                const notEmptyOrderWindows = orderWindows.filter(orderWindow => !!orderWindow.cartProducts?.length);
                const orderWindowsFromTable = getOrderWindowsForTable(notEmptyOrderWindows, tableId);
                setOrderWindowsToMove({ ordersToMove: orderWindowsFromTable, oldTableName: tableName });
                setSelectedTable(null);
            }
        },
        cancelMoveTable: () => {
            get().setSelectedTable(null);
            get().setOrderWindowsToMove({ ordersToMove: [], oldTableName: "" });
        },

        moveOrderToNewTable: async (table: Table) => {
            const { orderWindowsToMove, setOrderWindowsToMove, setSelectedTable } = get();
            const { setOrderWindows, setActiveOrderWindowId } = orderWindowsStore.getState();
            if (orderWindowsToMove.ordersToMove.length) {
                const { value } = await confirmOrCancelNotification(
                    `Vill du flytta bord ${orderWindowsToMove.oldTableName} till bord ${table.name}?`,
                    "",
                    "Ja",
                    "#89CB9C",
                    "Nej",
                    "question"
                );
                if (value) {
                    const updatedOrderWindows = orderWindowsToMove.ordersToMove.map(async (ow: OrderWindow) => {
                        const response = await newMothershipApolloClient.mutate({
                            mutation: UPSERT_ORDER_WINDOW_AND_ORDERS,
                            variables: { orderWindow: { ...ow, tableId: table.id } }
                        });
                        if (response.data) {
                            return response.data.upsertOrderWindowAndOrders;
                        }
                    });

                    Promise.all(updatedOrderWindows).then(values => {
                        setOrderWindowsToMove({ ordersToMove: [], oldTableName: "" });
                        setSelectedTable(null);
                        setActiveOrderWindowId(null);
                        setOrderWindows(values);
                    });
                } else {
                    setSelectedTable(null);
                }
            }
        },

        onSetSelectedTable: (table: Table) => {
            const { orderWindows, addOrderWindow, setActiveOrderWindowId } = orderWindowsStore.getState();
            const isSameTable = table?.id === get().selectedTable?.id;
            const shouldSelectTable = !isSameTable && !!table;

            if (shouldSelectTable) {
                const tableOrderWindows = getOrderWindowsForTable(orderWindows, table?.id);

                // This means the table doesn't have an order window, we need to add it.
                if (!tableOrderWindows?.length) {
                    const isTakeAway = false;
                    // TODO - We are adding an orderWindow to state on every press.
                    // Has to remove them when going back
                    const newOrderWindow = addOrderWindow(null, isTakeAway, table.id);
                    tableOrderWindows.push(newOrderWindow);
                }

                const orderWindowId = tableOrderWindows[0] ? tableOrderWindows[0].id : null;
                setActiveOrderWindowId(orderWindowId);
                get().setSelectedTable(table);
            } else {
                // This is our toggle functionality
                get().setSelectedTable(null);
            }
        },
        enterTableSelectionMode: () => get().setIsInTableSelectionMode(true),
        exitTableSelectionMode: () => get().setIsInTableSelectionMode(false)
    }))
);

export const useTableStore = createTrackedSelector(tableStore);
