import { createTrackedSelector } from "react-tracked";
import create from "zustand";
import moment from "moment";

import { SubscriptionState } from "Providers";
import { OnlineMenu, GiftCard, PartnerPromotion, Table, ActiveHour, FoodOptions, BrowseOptions } from "Types";
import { OnlineProductModalState } from "OnlineTypes";
import { SessionConstants } from "Constants";
import { getPersistedObject, persistToStorage } from "Utils";

export const defaultFoodOptions = {
    eatingPreference: "",
    pickupOptions: {
        date: "",
        time: "",
        isEarliest: false
    },
    postCode: "",
    deliveryInformation: null,
    cateringInformation: null
} as const;

const defaultBrowseMode = {
    isTemporarilyOffline: false,
    isClosed: false,
    closedOnlineOrders: false,
    isPaused: false,
    hasNoActiveMenus: false,
    rushHour: true,
    globallyClosed: false
};

const getInitialTable = () => getPersistedObject(SessionConstants.TABLE_MODE) ?? null;

export type OnlineStore = {
    activeMenu: OnlineMenu | null;
    setActiveMenu: (activeMenu: OnlineMenu | null) => void;

    allOnlineMenus: OnlineMenu[];
    setAllOnlineMenus: (allOnlineMenus: OnlineMenu[]) => void;

    activeMenuProdCatId: string | null;
    setActiveMenuProdCatId: (activeMenuProdCatId: string | null) => void;

    activeIndexes: number[];
    setActiveIndexes: (activeIndexes: number[]) => void;

    expandMobileCart: boolean;
    setExpandMobileCart: (expandMobileCart: boolean) => void;

    restaurantProductModalState: OnlineProductModalState | null;
    setRestaurantProductModalState: (restaurantProductModalState: OnlineProductModalState | null) => void;

    giftCardSettings: GiftCard | null;
    setGiftCardSettings: (giftCardSettings: GiftCard) => void;

    showGiftCardDrawer: boolean;
    setShowGiftCardDrawer: (showGiftCardDrawer: boolean) => void;

    subscriptionState: SubscriptionState;
    setSubscriptionState: (subscriptionState: SubscriptionState) => void;

    partnerPromotion: PartnerPromotion | null;
    setPartnerPromotion: (parnerPromotion: PartnerPromotion) => void;

    refs: any;
    setRefs: (refs: any) => void;

    handleSetActiveIndexes: (val: number) => void;
    resetActiveIndexes: () => void;

    table: Table | null;
    handleSetTable: (table: Table | null) => void;
    getInitialTable: () => Table | null;

    onlineActiveHours: ActiveHour[] | [];
    handleSetOnlineActiveHours: (onlineActiveHours: ActiveHour[] | []) => void;

    foodOptions: FoodOptions;
    handleSetFoodOptions: (foodOptions: FoodOptions, shouldUpdateSessionStorage?: boolean) => void;
    getInitialFoodOptions: () => FoodOptions;
    resetFoodOptionsFromStore: () => void;

    browseMode: Record<BrowseOptions, boolean>;
    setBrowseMode: (browseMode: Record<BrowseOptions, boolean>) => void;
    getInitialBrowseMode: () => Record<BrowseOptions, boolean>;

    backendDiff: number;
    setBackendDiff: (backendDiff: number) => void;
    getInitialBackendDiff: () => number;

    getAlcoholRefProductIds: () => Set<string>;
    setAlcoholRefProductIds: (alcoholRefProductIds: string[]) => void;
};

export const onlineStore = create<OnlineStore>(
    // Had to not use the devtools here until we can resolve which state is being set to a huge object
    //  Serialization can be super slow in some cases.
    //  We might have access to a sanitizer via Zustand.  Haven't checked yet
    //  Read this https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Troubleshooting.md#excessive-use-of-memory-and-cpu
    // wrapZustandStoreWithDevToolsIfNotProd((set, get) => ({
    (set, get) => ({
        activeMenu: null,
        setActiveMenu: (activeMenu: OnlineMenu | null) =>
            set(() => {
                return { activeMenu };
            }),

        allOnlineMenus: [],
        setAllOnlineMenus: (allOnlineMenus: OnlineMenu[]) => set(() => ({ allOnlineMenus })),

        activeMenuProdCatId: null,
        setActiveMenuProdCatId: (activeMenuProdCatId: string | null) => set(() => ({ activeMenuProdCatId })),

        activeIndexes: [],
        setActiveIndexes: (activeIndexes: number[]) => set(() => ({ activeIndexes })),

        expandMobileCart: false,
        setExpandMobileCart: (expandMobileCart: boolean) => set(() => ({ expandMobileCart })),

        restaurantProductModalState: null,
        setRestaurantProductModalState: (restaurantProductModalState: OnlineProductModalState | null) =>
            set(() => ({ restaurantProductModalState })),

        giftCardSettings: null,
        setGiftCardSettings: (giftCardSettings: GiftCard) => set(() => ({ giftCardSettings })),

        showGiftCardDrawer: false,
        setShowGiftCardDrawer: (showGiftCardDrawer: boolean) => set(() => ({ showGiftCardDrawer })),

        subscriptionState: {
            subscriptions: [],
            userSubscriptions: [],
            isLoading: true,
            isPromotionForShop: true,
            subscriptionsOnHold: {},
            hasAddedSubscriptionProduct: false
        },
        setSubscriptionState: (subscriptionState: SubscriptionState) => set(() => ({ subscriptionState })),

        refs: [],
        setRefs: (refs: any[]) => set(() => ({ refs })),

        partnerPromotion: null,
        setPartnerPromotion: (partnerPromotion: PartnerPromotion) => set(() => ({ partnerPromotion })),

        /**
         * This function is a bit confusing, but we didn't want to rewrite it right now
         * It looks like it's doing the following
         * val=2 [1,2,3,4,5] => [1,3,4,5] => [1,3,4,5,2]
         */
        handleSetActiveIndexes: (val: number) => {
            const filteredIndexes = (currActiveIndexes: number[]) => currActiveIndexes.filter(a => a != val);
            get().setActiveIndexes(Array.isArray(val) ? val : [...filteredIndexes(get().activeIndexes), val]);
        },
        resetActiveIndexes: () => set(() => ({ activeIndexes: [] })),

        // this refers to QR tables, even if uses the same object as tables in POS
        table: null,
        handleSetTable: (table: Table | null) => {
            persistToStorage(SessionConstants.TABLE_MODE, table);
            set(() => ({ table }));
        },
        getInitialTable: () => {
            const sessionBrowseMode = getPersistedObject(SessionConstants.TABLE_MODE);
            return sessionBrowseMode ?? null;
        },
        onlineActiveHours: [],
        handleSetOnlineActiveHours: (onlineActiveHours: ActiveHour[] | []) => set(() => ({ onlineActiveHours })),
        foodOptions: defaultFoodOptions,
        handleSetFoodOptions: (foodOptions: FoodOptions, shouldUpdateSessionStorage = true) => {
            if (shouldUpdateSessionStorage) {
                persistToStorage(SessionConstants.FOOD_OPTIONS, foodOptions);
            }
            set(() => ({ foodOptions }));
        },
        resetFoodOptionsFromStore: () => {
            get().handleSetFoodOptions(defaultFoodOptions, false);
        },
        getInitialFoodOptions: () => {
            const sessionFoodOptions = getPersistedObject(SessionConstants.FOOD_OPTIONS);
            if (sessionFoodOptions === undefined) {
                return defaultFoodOptions;
            }
            const initialBackendDiff = getPersistedObject(SessionConstants.BACKEND_DIFF);
            const hasDayPassed = moment(
                `${sessionFoodOptions.pickupOptions.date} ${sessionFoodOptions.pickupOptions.time}`
            ).isBefore(moment().add(initialBackendDiff, "ms"), "day");
            if (hasDayPassed) {
                return defaultFoodOptions;
            }
            return {
                ...sessionFoodOptions,
                pickupOptions: {
                    ...sessionFoodOptions.pickupOptions,
                    isEarliest: false
                }
            };
        },
        browseMode: defaultBrowseMode,
        getInitialBrowseMode: () => {
            const sessionBrowseMode = getPersistedObject(SessionConstants.BROWSE_MODE);
            return sessionBrowseMode ?? defaultBrowseMode;
        },
        setBrowseMode: (browseMode: Record<BrowseOptions, boolean>) => set(() => ({ browseMode })),

        backendDiff: 0,
        setBackendDiff: (backendDiff: number) => set(() => ({ backendDiff })),
        getInitialBackendDiff: () => getPersistedObject(SessionConstants.BACKEND_DIFF),

        getAlcoholRefProductIds: () => {
            const persistedAlcoholIds = getPersistedObject(SessionConstants.ALCOHOL_REF_PRODUCTS);
            return new Set<string>(persistedAlcoholIds ?? []);
        },
        setAlcoholRefProductIds: (alcoholRefProductIds: string[]) => {
            persistToStorage(SessionConstants.ALCOHOL_REF_PRODUCTS, alcoholRefProductIds);
        }
    })
);

export const useOnlineStore = createTrackedSelector(onlineStore);

export const useInitializeOnlineStore = () => {
    const backendDiff = onlineStore.getState().getInitialBackendDiff();
    const foodOptions = onlineStore.getState().getInitialFoodOptions();
    const browseMode = onlineStore.getState().getInitialBrowseMode();
    const table = onlineStore.getState().getInitialTable();

    // Add more states here as necessary
    onlineStore.setState({
        backendDiff,
        foodOptions,
        browseMode, 
        table
    });
};
