import { ColorThemes } from "Types";

export const expressColors: Partial<ColorThemes> = {
    expressPrimary: "#084625",
    expressTextColor: "#182114",
    expressPrimaryLight: "#527d66",
    expressSecondaryDark: "#FFF0D9",
    expressSecondary: "#FEF7EC",
    expressSecondaryDarkAlpha: {
        "50": "rgba(255,240,217,0.04)",
        "100": "rgba(255,240,217,0.06)",
        "200": "rgba(255,240,217,0.08)",
        "300": "rgba(255,240,217,0.16)",
        "400": "rgba(255,240,217,0.24)",
        "500": "rgba(255,240,217,0.36)",
        "600": "rgba(255,240,217,0.48)",
        "700": "rgba(255,240,217,0.64)",
        "800": "rgba(255,240,217,0.80)",
        "900": "rgba(255,240,217,0.92)"
    },
    expressLightGrey: "#E7E7E7",
    expressMediumGrey: "#D9D8D8",
    expressDarkGrey: "#8C8C8C",
    expressSuccess: "#41A871",
    expressWarning: "#FFD542",
    expressError: "#BC2828"
};
