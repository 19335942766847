import { createTrackedSelector } from "react-tracked";
import create from "zustand";

import { IModalContext, ModalDefinition } from "Providers";
import { wrapZustandStoreWithDevToolsIfNotProd } from "./storeUtils";

export const modalStore = create<IModalContext<any>>(
    wrapZustandStoreWithDevToolsIfNotProd((set, get) => ({
        activeModals: [],
        openModal: (modal: string | ModalDefinition, modalProps?: any) => {
            set(() => {
                if ((modal as ModalDefinition).name) {
                    const modalName = (modal as ModalDefinition).name as string;
                    return { activeModals: [...get().activeModals, { name: modalName, modalProps }] };
                } else {
                    const modalName = modal as string;
                    return { activeModals: [...get().activeModals, { name: modalName, modalProps }] };
                }
            });
        },
        closeModal: (modalToClose: string | ModalDefinition) => {
            set(() => {
                if ((modalToClose as ModalDefinition).name) {
                    const modalName = (modalToClose as ModalDefinition).name;
                    return { activeModals: get().activeModals.filter(({ name }) => name !== modalName) };
                } else {
                    const modalName = modalToClose;
                    return { activeModals: get().activeModals.filter(({ name }) => name !== modalName) };
                }
            });
        },
        closeAllModals: () => set(() => ({ activeModals: [] })),
        isModalOpen: (modal: ModalDefinition) => {
            return !!get().activeModals.find(activeModal => activeModal.name === modal?.name);
        },
        modalContent: {}
    }))
);

export const useModalStore = createTrackedSelector(modalStore);
