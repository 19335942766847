export const colors = {
    primary: "#89cb9c",
    primaryDark: "#54b26f",
    accent: "#06D19C",
    accentDark: "#05bd8d",
    text: "#666",
    heading: "#111",
    // base: "#F7F7F7",
    base: "#FFF",
    lines: "#EEE",
    error: "#FF5232",
    icon: "#666",
    baseDark: "#333C44",
    transparent: "transparent",
    black: "#000000",
    white: "#FFFFFF",
    newPrimary: "#084625",
    newPrimaryHover: "#005F42",
    newPrimaryFont: "#FCDFB2",
    midnightGreen: "#0B3C49",

    primaryAlpha: {
        "50": "rgba(8, 70, 37, 0.04)",
        "100": "rgba(8, 70, 37, 0.06)",
        "200": "rgba(8, 70, 37, 0.08)",
        "300": "rgba(8, 70, 37, 0.16)",
        "400": "rgba(8, 70, 37, 0.24)",
        "500": "rgba(8, 70, 37, 0.36)",
        "600": "rgba(8, 70, 37, 0.48)",
        "700": "rgba(8, 70, 37, 0.64)",
        "800": "rgba(8, 70, 37, 0.80)",
        "900": "rgba(8, 70, 37, 0.92)"
    },
    secondaryAlpha: {
        "50": "rgba(252, 223, 178, 0.04)",
        "100": "rgba(252, 223, 178, 0.06)",
        "200": "rgba(252, 223, 178, 0.08)",
        "300": "rgba(252, 223, 178, 0.16)",
        "400": "rgba(252, 223, 178, 0.24)",
        "500": "rgba(252, 223, 178, 0.36)", 
        "600": "rgba(252, 223, 178, 0.48)",
        "700": "rgba(252, 223, 178, 0.64)",
        "800": "rgba(252, 223, 178, 0.80)",
        "900": "rgba(252, 223, 178, 0.92)"
    },
    whiteAlpha: {
        "50": "rgba(255, 255, 255, 0.04)",
        "100": "rgba(255, 255, 255, 0.06)",
        "200": "rgba(255, 255, 255, 0.08)",
        "300": "rgba(255, 255, 255, 0.16)",
        "400": "rgba(255, 255, 255, 0.24)",
        "500": "rgba(255, 255, 255, 0.36)",
        "600": "rgba(255, 255, 255, 0.48)",
        "700": "rgba(255, 255, 255, 0.64)",
        "800": "rgba(255, 255, 255, 0.80)",
        "900": "rgba(255, 255, 255, 0.92)"
    },
    blackAlpha: {
        "50": "rgba(0, 0, 0, 0.04)",
        "100": "rgba(0, 0, 0, 0.06)",
        "200": "rgba(0, 0, 0, 0.08)",
        "300": "rgba(0, 0, 0, 0.16)",
        "400": "rgba(0, 0, 0, 0.24)",
        "500": "rgba(0, 0, 0, 0.36)",
        "600": "rgba(0, 0, 0, 0.48)",
        "700": "rgba(0, 0, 0, 0.64)",
        "800": "rgba(0, 0, 0, 0.80)",
        "900": "rgba(0, 0, 0, 0.92)"
    },
    gray: {
        "50": "#FAFAFA",
        "100": "#F7FAFC",
        "200": "#EDF2F7",
        "300": "#E2E8F0",
        "400": "#CBD5E0",
        "500": "#A0AEC0",
        "600": "#718096",
        "700": "#4A5568",
        "800": "#2D3748",
        "900": "#1A202C"
    },
    red: {
        "100": "#FFF5F5",
        "200": "#FED7D7",
        "300": "#FEB2B2",
        "400": "#FC8181",
        "500": "#F56565",
        "600": "#E53E3E",
        "700": "#C53030",
        "800": "#9B2C2C",
        "900": "#742A2A"
    },
    orange: {
        "100": "#fffaf0",
        "200": "#feebc8",
        "300": "#fbd38d",
        "400": "#f6ad55",
        "500": "#ed8936",
        "600": "#dd6b20",
        "700": "#c05621",
        "800": "#9c4221",
        "900": "#7b341e"
    },
    yellow: {
        "100": "#FFFFF0",
        "200": "#FEFCBF",
        "300": "#FAF089",
        "400": "#F6E05E",
        "500": "#ECC94B",
        "600": "#D69E2E",
        "700": "#B7791F",
        "800": "#975A16",
        "900": "#744210"
    },
    green: {
        "100": "#F0FFF4",
        "200": "#C6F6D5",
        "300": "#9AE6B4",
        "400": "#68D391",
        "500": "#48BB78",
        "600": "#38A169",
        "700": "#2F855A",
        "800": "#276749",
        "900": "#22543D"
    },
    teal: {
        "100": "#E6FFFA",
        "200": "#B2F5EA",
        "300": "#81E6D9",
        "400": "#4FD1C5",
        "500": "#38B2AC",
        "600": "#319795",
        "700": "#2C7A7B",
        "800": "#285E61",
        "900": "#234E52"
    },
    blue: {
        "100": "#EBF8FF",
        "200": "#BEE3F8",
        "300": "#90CDF4",
        "400": "#63B3ED",
        "500": "#4299E1",
        "600": "#3182CE",
        "700": "#2B6CB0",
        "800": "#2C5282",
        "900": "#2A4365"
    },
    indigo: {
        "100": "#EBF4FF",
        "200": "#C3DAFE",
        "300": "#A3BFFA",
        "400": "#7F9CF5",
        "500": "#667EEA",
        "600": "#5A67D8",
        "700": "#4C51BF",
        "800": "#434190",
        "900": "#3C366B"
    },
    purple: {
        "100": "#FAF5FF",
        "200": "#E9D8FD",
        "300": "#D6BCFA",
        "400": "#B794F4",
        "500": "#9F7AEA",
        "600": "#805AD5",
        "700": "#6B46C1",
        "800": "#553C9A",
        "900": "#44337A"
    },
    pink: {
        "100": "#FFF5F7",
        "200": "#FED7E2",
        "300": "#FBB6CE",
        "400": "#F687B3",
        "500": "#ED64A6",
        "600": "#D53F8C",
        "700": "#B83280",
        "800": "#97266D",
        "900": "#702459"
    }
};
