import { createTrackedSelector } from "react-tracked";
import create from "zustand";
import { persist } from "zustand/middleware";
import { openDB } from 'idb';

import { wrapZustandStoreWithDevToolsIfNotProd } from "./storeUtils";
import { MenuPreviewProduct, MenuUploadParseResults, MenuUserInfo } from "Types";

// Upload states
export enum MENU_UPLOAD_STATE {
    SELECTING = "SELECTING",
    PREVIEW = "PREVIEW",
    UPLOADING = "UPLOADING",
    FINISHED = "FINISHED"
}

export enum MENU_SIGNUP_STATE {
    SIGNING_UP = "SIGNING_UP",
    SIGNED_UP = "SIGNED_UP",
}


////////////////////////////////////////
// IndexedDB PART
const DB_NAME = 'fronty-db';
const STORE_NAME = 'menu-upload-images';
const IMAGE_KEY = 'base64Image';

const getDb = async () => {
    return openDB(DB_NAME, 1, {
        upgrade(db) {
            db.createObjectStore(STORE_NAME);
        },
    });
};

// TODO: Maybe we want to expand this to keep the last X images?
const setBase64ImageInDb = async (key: string, base64Image: string) => {
    const db = await getDb();
    await db.put(STORE_NAME, base64Image, key);
};

const getBase64ImageFromDb = async (key: string): Promise<string | undefined> => {
    const db = await getDb();
    return db.get(STORE_NAME, key);
};

const deleteBase64ImageFromDb = async (key: string) => {
    const db = await getDb();
    await db.delete(STORE_NAME, key);
};
// END
////////////////////////////////////////


export type MenuUploadStore = {
    // UPLOAD
    isLoading: boolean;
    setIsLoading: (isReady: boolean) => void;

    base64Image: string | undefined;
    setBase64Image: (base64Image: string) => void;

    initialScanResults: string[] | undefined;
    setInitialScanResults: (initialScanResults: string[]) => void;

    uploadResults: MenuUploadParseResults | undefined;
    setUploadResults: (uploadResults: MenuUploadParseResults) => void;

    uploadError: any | undefined;
    setUploadError: (uploadError: any) => void;

    menuUploadState: MENU_UPLOAD_STATE;
    setMenuUploadState: (menuUploadState: MENU_UPLOAD_STATE) => void;

    menuPreviewProducts: MenuPreviewProduct[];
    setMenuPreviewProducts: (menuPreviewProducts: MenuPreviewProduct[]) => void;

    clearUploadData: () => void;

    // PREVIEW/SIGNUP
    menuSignupState: MENU_SIGNUP_STATE;
    setMenuSignupState: (menuSignupState: MENU_SIGNUP_STATE) => void;

    userInfo: MenuUserInfo | undefined;
    setUserInfo: (userInfo: MenuUserInfo) => void;

    // OTHER
    // Load any store object from IndexedDB.  This needs to be called on page load
    loadStore: () => void;
};

export const menuUploadStore = create<MenuUploadStore>(
    wrapZustandStoreWithDevToolsIfNotProd(
        persist(
            (set, get) =>
            ({
                // UPLOAD
                isLoading: true,
                setIsLoading: (isReady: boolean) => set({ isLoading: isReady }),

                base64Image: undefined,
                setBase64Image: async (base64Image: string) => {
                    await setBase64ImageInDb(IMAGE_KEY, base64Image);
                    set({ base64Image });
                },

                initialScanResults: undefined,
                setInitialScanResults: (initialScanResults: string[]) =>
                    set({
                        initialScanResults
                    }),

                uploadResults: undefined,
                setUploadResults: (uploadResults: MenuUploadParseResults) =>
                    set({
                        uploadResults
                    }),

                uploadError: undefined,
                setUploadError: (uploadError: any) => set({ uploadError }),

                menuUploadState: MENU_UPLOAD_STATE.SELECTING,
                setMenuUploadState: (menuUploadState: MENU_UPLOAD_STATE) =>
                    set({
                        menuUploadState
                    }),

                menuPreviewProducts: [],
                setMenuPreviewProducts: (menuPreviewProducts: MenuPreviewProduct[]) => set({ menuPreviewProducts }),

                clearUploadData: async () => {
                    await deleteBase64ImageFromDb('base64Image');
                    set({
                        isLoading: false,
                        base64Image: undefined,
                        initialScanResults: undefined,
                        uploadResults: undefined,
                        uploadError: undefined,
                        menuUploadState: MENU_UPLOAD_STATE.SELECTING,
                        menuPreviewProducts: [],
                        // userInfo, menuSignupState "COULD" be reset here, but resetting the menuSignupState allows the user to access the form again
                    });
                },

                // PREVIEW/SIGNUP
                menuSignupState: MENU_SIGNUP_STATE.SIGNING_UP,
                setMenuSignupState: (menuSignupState: MENU_SIGNUP_STATE) => set({ menuSignupState }),

                userInfo: undefined,
                setUserInfo: (userInfo: MenuUserInfo) => set({ userInfo }),

                // OTHER
                loadStore: async () => {
                    const base64Image = await getBase64ImageFromDb(IMAGE_KEY);
                    if (base64Image) {
                        const sizeInMB = (base64Image.length * 3) / 4 / 1000000;
                    }
                    set({ base64Image, isLoading: false });
                },
            } as MenuUploadStore),
            {
                name: "menuUploadStore",
                partialize: (state) => {
                    // We don't want to save the base64Image to localStorage, instead we save it to IndexedDB
                    // Any further exclusions can be added here
                    const { base64Image, ...stateWithoutBase64Image } = state;

                    // IMPORTANT: Everything listed here is persisted to localStorage
                    return {
                        ...stateWithoutBase64Image
                    }
                },
            }
        )
    )
);

export const useMenuUploadStore = createTrackedSelector(menuUploadStore);
