import { PaymentMethod } from "Constants";
import { AddonsHashMap, AddonsHashMapInput, Ingredients } from "Providers/pos/utils/createAddonsHashMap";
import { PaymentTab, DTInfo } from "Types";

const getIngredients = ({ ingredientLimiters, id }: AddonsHashMapInput) => {
    return ingredientLimiters.reduce<Ingredients>((acc, next) => {
        const found = acc.find(({ ingredient }) => ingredient.name === next.ingredient.name);
        if (!found) {
            acc.push({ ...next, ingredient: { ...next.ingredient, groupId: id } });
        }
        return acc;
    }, []);
};

const defaultPaymentTab: PaymentTab = {
    id: "",
    displayHeaderText: "",
    displayText: "Väntar på kund",
    status: undefined,
    paymentMethod: undefined,
    driveThrough: false,
    cancelledByShop: undefined,
    terminalId: undefined,
    postponePayment: false,
    DTInfo: {
        orderNumber: undefined,
        orderId: undefined
    }
};

export const addNewPaymentTab = (newProps: Object, activePaymentTabId: string): PaymentTab => {
    return {
        ...defaultPaymentTab,
        ...newProps,
        id: activePaymentTabId
    };
};

export const createAddonsHashMap = (addons: AddonsHashMapInput[]) => {
    return addons.reduce<AddonsHashMap>((acc, next) => {
        next.refProductIds.forEach(refProductId => {
            const nextIngredientGroup = {
                name: next.name,
                limit: next.limit,
                ingredients: getIngredients(next),
                sortOrder: next.sortOrder,
                displayByName: next.displayByName
            };

            acc[refProductId] = acc[refProductId]
                ? acc[refProductId].concat(nextIngredientGroup)
                : [nextIngredientGroup];
        });
        return acc;
    }, {});
};

export const getDisplayText = (isDT: boolean, DTInfo: DTInfo, paymentMethod: string) => {
    let displayText = "";
    if (isDT && DTInfo) {
        displayText = `Order Nr: ${DTInfo.orderNumber}`;
    } else if (paymentMethod === PaymentMethod.INVOICE) {
        displayText = "Välj fakturakund";
    } else if (paymentMethod === PaymentMethod.POSTPONED) {
        displayText = "Order tillagd och parkerad";
    }
    return displayText || defaultPaymentTab.displayText;
};



export const updatePaymentTab = (activePaymentTab: PaymentTab, newProps: Object): PaymentTab => {
    return {
        ...activePaymentTab,
        ...newProps,
        displayHeaderText: defaultPaymentTab.displayHeaderText,
        displayText:
            activePaymentTab.paymentMethod === "POSTPONED"
                ? "Order tillagd och parkerad"
                : defaultPaymentTab.displayText
    };
};