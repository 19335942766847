import { createTrackedSelector } from "react-tracked";
import { PersistConstants, defaultValues } from "Constants";
import { SelectedCompany, Shop, CompanyLocale, CountryLocaleId } from "Types";
import create from "zustand";
import { wrapZustandStoreWithDevToolsIfNotProd } from "./storeUtils";
import { getCompanyLocale, persistToStorage } from "Utils";

export type QoplaStore = {
    selectedShop: Shop | null;
    setSelectedShop: (shop: Shop | null) => void;
    selectedCompany: SelectedCompany;
    companyLocale: CompanyLocale;
    setSelectedCompany: (selectedCompany: SelectedCompany) => void;
    setSelectedCompanyAndShop: (selectedCompany: SelectedCompany, shop: Shop | null) => void;
};
export const qoplaStore = create<QoplaStore>(
    wrapZustandStoreWithDevToolsIfNotProd((set, get) => ({
        selectedShop: defaultValues.selectedShop,
        setSelectedShop: (shop: Shop | null) => {
            set(() => {
                persistToStorage(PersistConstants.SELECTED_SHOP, shop);
                return { selectedShop: shop };
            });
        },
        //@ts-ignore
        selectedCompany: defaultValues.selectedCompany as SelectedCompany,
        //@ts-ignore
        companyLocale: new CompanyLocale(CountryLocaleId.sv_SE),
        setSelectedCompany: (selectedCompany: SelectedCompany) =>
            set(() => {
                persistToStorage(PersistConstants.SELECTED_COMPANY, selectedCompany);
                const companyLocale = getCompanyLocale(selectedCompany);
                return { selectedCompany, companyLocale };
            }),
        setSelectedCompanyAndShop: (selectedCompany: SelectedCompany, shop: Shop | null) =>
            set(() => {
                persistToStorage(PersistConstants.SELECTED_SHOP, shop);
                persistToStorage(PersistConstants.SELECTED_COMPANY, selectedCompany);
                const companyLocale = getCompanyLocale(selectedCompany);
                return {
                    selectedCompany,
                    selectedShop: shop,
                    companyLocale
                };
            })
    }))
);

export const useQoplaStore = createTrackedSelector(qoplaStore);
